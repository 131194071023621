import { HeadFC } from 'gatsby'
import React from 'react'
import { Home } from '../features/Home'

export const Head: HeadFC = () => (
    <>
        <title>Suhl Gongs | Gongs for the world</title>
        <title>Hello World</title>
        <meta name="description" content="Gongs for the world" />
    </>
)

export default Home
